<template>
    <footer class="py-6 px-8 sm:px-12 bg-slate-400 flex justify-between flex-col  sm:flex-row ">
        <p class="text-center text-gray-100 text-xs tracking-wide">
          &copy;2024 ISUKJADI All rights reserved | email : <a href="mailto: Budi@avasoft.co">si BUDI</a> atau <a
            href="mailto:dicky@dickyjiang.com">si DIKKI</a>
        </p>
        <div>
            <p>
              <nuxt-link class="w-full text-center text-gray-100 text-xs tracking-wide" to="/kebijakanPrivasi">
                Kebijakan Privasi
              </nuxt-link>
            </p>
        </div>
      </footer>
</template>

<script setup>

</script>

<style scoped>

</style>