<template>
  <header class="sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3">
    <div class="flex items-center justify-between px-4 py-3 sm:p-0">
      <div>
        <nuxt-link to="/">
          <img
            class="h-12 md:h-16"
            src="../assets/images/hhj_logo.svg"
            alt="hitung harga jual logo"
          />
        </nuxt-link>
      </div>
      <div class="sm:hidden">
        <button
          @click="isOpen = !isOpen"
          type="button"
          class="block text-gray-500 hover:text-slate-700 focus:text-slate-700 focus:outline-none"
        >
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </div>
    </div>
    <nav
      :class="isOpen ? 'block' : 'hidden'"
      class="px-4 pt-2 pb-4 sm:flex sm:p-0 items-center gap-4"
    >
      <div class="relative m-0 my-2 sm:m-3">
        <img
          class="h-12 md:h-16 absolute z-10 -top-3 -left-8 hidden lg:block"
          src="../assets/images/INSTANT NOODLE 1.png"
          alt="hitung online food"
        />
        <button
          class="bg-green-600 hover:bg-green-700 text-xs text-white drop-shadow-xl rounded-full font-semibold py-2 md:py-3 px-5 focus:outline-none focus:shadow-outline"
          type="button"
        >
          <nuxt-link to="/">Hitung Online Food</nuxt-link>
        </button>
      </div>
      <div class="relative mx-0 my-2 sm:m-3">
        <img
          class="h-12 md:h-16 absolute z-10 -left-8 -top-3 hidden lg:block"
          src="../assets/images/olshop_orange-icon.png"
          alt="hitung online shop"
        />
        <button
          class="bg-fuchsia-500 hover:bg-fuchsia-700 text-xs  text-white drop-shadow-xl rounded-full font-semibold py-2 md:py-3 px-5 focus:outline-none focus:shadow-outline"
          type="button"
        >
          <nuxt-link to="/hitungProduct">Hitung Online Shop</nuxt-link>
        </button>
      </div>

      <a href="https://www.instagram.com/hitung.harga.jual/">
        <img src="../assets/images/instagram.svg" alt="instagram" />
      </a>
      <div>
        <nuxt-link class="text-sm text-slate-500 font-medium" to="/blogs"> Berita & Tips </nuxt-link>
      </div>
      <div>
        <nuxt-link class="text-sm text-slate-500 font-medium" to="/tentangKami"> Tentang Kami </nuxt-link>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
